

.Dock table {
    margin-bottom: 10px;
}

.Dock thead tr {
    background-color: lightyellow;
}

.Dock.loaded:not(.driver_hard):not(.stuart):not(.cross_dock):not(.uber):not(.canceled-external-job) thead tr {
    background: repeating-linear-gradient(
            45deg,
            lightyellow,
            lightyellow 10px,
            #ffffb2 10px,
            #ffffb2 20px
    );

}

.Dock.driver_hard thead tr  {
    background-color: rgba(255, 165, 0, 0.44);
}

.DockList.deliveries-todo .Dock.locked:not(.stuart):not(.uber) thead tr:first-child  {
    background-color: lightgreen;
}

.DockList.deliveries-todo .Dock.canceled-external-job thead tr:first-child  {
    background-color: indianred;
}

.Dock {
    padding:0 5px 0 0;
}

.Dock thead form {
    margin-top: -2px;
    float:right;
    min-width:35%;
    max-width:60%;
    text-align:right;
}

/*.Dock.driver_hard thead form {*/
    /*width:50%;*/
/*}*/

/*.Dock.locked thead form {*/
    /*width: 50%;*/
/*}*/



.Dock thead form.updating {
    color:Lightgrey;
}

.Dock thead tr.subheader {
    font-size: 11px;
    font-weight: 300;
    background-color: white;
    height: 13px;
}

.Dock select.form-control {
    padding: 1px 2px 1px 2px;
    height: 20px;
    font-size: 11px;
    width: 100px;
}

.Dock .form-group  {
    height: 20px;
    margin-bottom: 0;
}

.Dock .last-run {
    margin-left: 5px;
}


/*noinspection CssUnusedSymbol*/
.Dock .stuart-running, .Dock .uber-running {
    padding: 0 5px 0 5px;
    font-size: 11px;
    font-weight: normal;
}

.Dock .btn {
    margin-left: 5px;
    padding: 0 5px 0 5px;
    font-size: 11px;
}

.Dock i {
    margin-left: 2px;
}

.Dock .DefaultDropZone td {
    text-align: center;
}

.Dock .draggable {
    /*noinspection CssUnknownTarget*/
    background-image: url("/draggable3_25.png");
    background-repeat: no-repeat;
    height: 25px;
    width: 25px;
}
.Dock .draggable.locked {
    filter: alpha(Opacity=25);
    opacity: 0.25;
}


.Dock.is-dragging-dock {
    opacity: 0.5;
    background-color: white !important;
}

.Dock.is-over-dock  tr {
    background-color: LightGreen !important;
}

.Dock .first-cmd {
    display: none;
}

.Dock .express {
    display: initial;
}

.Dock .OrderPseudoZoneName {
    display: none;
}

.Dispatch .Dock .link_admin {
    color: black;
    font-size: 1.2em;
    margin-left: 15px;
}
.Dispatch .Dock thead form {
  min-width: initial
}

@keyframes redFlashy {
    from { background-color: red; }
    to { background-color: white; }
    }

.Delay-time-take-bags {
    animation: redFlashy 1s infinite alternate;
}

@keyframes orangeFlashy {
    from { background-color: rgba(255, 128, 0, 0.732); }
    to { background-color: white; }
    }

.Limit-time-take-bags {
    animation: orangeFlashy 2s infinite alternate;
}