

.PrioItem {
    max-width: 50%;
    min-width: 400px;
}

.PrioItem .prio-label {
    padding: 5px;
    /*border: 1px #333333 solid;*/
    width: 100%;
}

.PrioItem:not(.disabled) .prio-name {
    font-weight: bold;
}

.PrioItem .insert-zone {
    height: 5px;
    font-size: 0;
    width: 100%;
}

.PrioItem.updating {
    color:dimgrey;
}

.PrioItem .late-title {
    color:red;
    font-weight: bold;
}


.PrioItem.is-dragging-row {
    opacity: 0.5;
    background-color: white !important;;
}
.PrioItem.is-over-row .insert-zone {
    background-color: LightGreen !important;
}

.PrioItem .div-alert {
    margin: 5px 0;
}
