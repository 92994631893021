
.TransitArea {
    height: 19px;
    min-width: 5px;
}
.TransitArea .transit_area,
.TransitArea .transit_subarea {
  cursor: pointer;
}
.TransitArea .transit_area:hover,
.TransitArea .transit_subarea:hover {
  text-decoration: underline;
}
