.Support {
    margin-top: 20px
}
.super_checkbox.checkbox {
  margin:0;
}
.super_checkbox.checkbox label {
  padding-left: 0;
}
.super_checkbox input[type="checkbox"] {
  display: none;
}
.super_checkbox input[type="checkbox"] + .checkbox_checked,
.super_checkbox input[type="checkbox"] + .checkbox_checked + .checkbox_not_checked {
    padding: 1px 5px;
    font-size: 10px;
    line-height: 1.5;
    border-radius: 3px 0 0 3px;
}
.super_checkbox input[type="checkbox"] + .checkbox_checked {
  background-color: #fff;
  border-color: #ccc;
  color: #333333
}
.super_checkbox input[type="checkbox"] + .checkbox_checked + .checkbox_not_checked {
  color: #fff;
  background-color: #286090;
  border-color: #122b40;
  border-radius: 0 3px 3px 0;
}
.super_checkbox input[type="checkbox"]:checked + .checkbox_checked {
  color: #fff;
  background-color: #286090;
  border-color: #122b40;
}
.super_checkbox input[type="checkbox"]:checked + .checkbox_checked + .checkbox_not_checked {
  background-color: #fff;
  border-color: #ccc;
  color: #333333
}
.display-linebreak {
  white-space: pre-line;
}
