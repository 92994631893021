

/*noinspection CssUnusedSymbol*/
.ExtraDelay, .ExpressExtraDelay, .ExpressPrioExtraDelay, .CourierExtraDelay, .RainBonus,
.FrichtiExtraDelay, .FrichtiExpressPrioExtraDelay {
    font-size: 11px;
    text-align: right;
    padding-right: 5px;
}
.ExtraDelay{
    width: 110px;
}
.ExpressExtraDelay{
    width: 170px;
}
.ExpressPrioExtraDelay{
    width: 220px;
}
.CourierExtraDelay{
    width: 120px;
}
.RainBonus.RainBonus{
    width: 150px;
}

/*noinspection CssUnusedSymbol*/
.FrichtiExtraDelay{
    width: 170px;
}

/*noinspection CssUnusedSymbol*/
.FrichtiExpressPrioExtraDelay{
    width: 270px;
}

.ExtraDelay input, .ExpressExtraDelay input, .ExpressPrioExtraDelay input, .CourierExtraDelay input,
.FrichtiExpressPrioExtraDelay input, .FrichtiExtraDelay input {
    width: 50px !important;
    padding: 2px 2px 2px 2px;
    height: 18px;
    font-size: 12px;
}
.ExpressPrioExtraDelay input[type="checkbox"],
.CourierExtraDelay input[type="checkbox"],
.FrichtiExpressPrioExtraDelay input[type="checkbox"] {
    width: 18px !important;
}

/*noinspection CssUnusedSymbol*/
.ExtraDelay.updating,
.ExpressExtraDelay.updating,
.ExpressPrioExtraDelay.updating,
.CourierExtraDelay.updating,
.FrichtiExtraDelay.updating,
.FrichtiExpressPrioExtraDelay.updating,
.RainBonus.updating {
    color: grey;
}

.dqp-header h4 div {
    display: inline-flex;
}
