
.late-list tr.Row {
    background: rgba(255, 165, 0, 0.44) !important;
}

.late-list .Row.updating {
    color:inherit;
}

.late-list .Row.updating  .Late button {
    background-color: #cDcDcD;
}


.error-list h4 {
    background-color: red;
    color: white;
    font-weight: bold;
}

.DeliveryRow i.no_finalized {
  color: #c9302c;
  margin-left: 1px;
}
