

.UserOrder h3{
    text-align: center;

}

.UserOrder h3 .badge {
    margin-left: 5px;
    padding: 2px 5px;
}

.UserOrder h6.subtitle {
    font-size: 12px;
    text-align: center;
}
.UserOrder h4.subtitle {
    text-align: center;
    background-color: inherit;
    margin-top: -5px;
}

.modal-body {
    padding: 5px 10px;
}

.modal-body h4 {
    background-color: white;
    text-align: center;
    font-weight: bold;
}

.UserOrder .nav-tabs a{
    padding: 5px;
}

.UserFraudDetail .popover-body {
  width: 400px;
  height: auto;
}
