.OrderCrossDocking .current-order {
  font-weight: bold;
}

.OrderCrossDocking tr:hover {
  cursor: pointer;
}

.OrderCrossDocking tr.current-order:hover, .OrderCrossDocking tr.docking-title:hover {
  cursor: auto;
}
