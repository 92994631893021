.OrderDetail .order-nav .nav-item {
  background-color: #f5f5f5;
}
.OrderDetail .order-nav .nav-item:first-child {
  border-radius: 5px 0 0 5px;
  overflow: hidden;
}
.OrderDetail .order-nav .nav-item:last-child {
  border-radius: 0 5px 5px 0;
  overflow: hidden;
}
.OrderDetail .order-nav .nav-item.active {
  background-color: #e6e6e6;
}
.OrderDetail .order-nav a {
  color: #737373;
}
.OrderDetail .order-nav .nav-item.active a {
  color: black;
}

.OrderDetail tr:first-child td, .OrderDetail tr:first-child th {
    border-top: 0;
}

.OrderDetail .OrderSummary tr td:first-child,
.OrderDetail .OrderFinancial tr td:first-child,
.OrderDetail .OrderActions tr td:first-child,
.OrderDetail .OrderPackingDetail tr td:first-child,
.OrderDetail .OrdersLinked tr td:first-child,
.OrderDetail .OrderDeliveryDetail tr td:first-child,
.OrderDetail .OrderProductsEmpty tr td:first-child {
    width: 50%;
}

.OrderDetail .panel-heading-sm{
    height: 22px;
    padding: 2px 5px;
}

.OrderDetail .panel-body-sm{
    padding: 5px 5px;
}

.OrderDetail .panel-body-sm h4 {
    margin-top: 5px;
    background-color: #FFFFFF;
    text-align: left;
}
