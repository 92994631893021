.PackingNowList .DefaultDropZone {
    font-size: 1px;
    height: 15px;
}

.PackingNowList .title-right {
    font-size: 14px;
    color: #333333;
}

.PackingNowList .DefaultDropZone td{
    height: 15px;
}
