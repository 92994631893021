.DepositUser {
  padding: 20px 0;
}
.DepositUser .DepositUserCurrent td { vertical-align: middle; }
#deposit-detail-form .badge.deposit-cancel { background-color: #a800ab; color: white; }
#deposit-detail-form .badge.deposit-fix { background-color: red; color: white; }
#deposit-detail-form .badge.deposit-user { background-color: orange; color: black; }
#deposit-detail-form .badge.deposit-driver { background-color: #c1f1a8; color: black; }
#deposit-detail-form .badge.deposit-fix-partial { border:1px solid #0097A7; background-color: #E0F7FA; color: black; }
#deposit-detail-form .badge.deposit-fix-total { background-color: #0097A7; color: white; }
#deposit-detail-form .badge.deposit-canceled-partial { border:1px solid #b416e8; background-color: #f3d0ff; color: black; }
#deposit-detail-form .badge.deposit-canceled-total { background-color: #b416e8; color: white; }
