

.Slot {
    width: 75px;
}

.Slot .slot-etd{
    font-size: 9px;
}


.Address a, .Address a:hover, .Address a:active{
    color:#333333;
}
