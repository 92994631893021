[id^="tooltip-stripe-infos"] ul {
  list-style: none;
  padding:0;
  margin:0;
}
.popover-body { width :290px; height: 120px; }

.StripeList thead th,
.StripeList tbody td.col-date { white-space: nowrap; }
.StripeList .col-order-id,
.StripeList .col-amount,
.StripeList .col-score-stripe,
.StripeList .col-infos,
.StripeList .col-3ds { text-align: center; }
.StripeList .ch_succeeded td,
.StripeList .pi_succeeded td {
  background-color: #e0ffe0;
}
.StripeList .col-infos { cursor: pointer; }
.StripeList td.col-3ds .fa-lock {
  color: #1eca1e;
}
.StripeList td.col-3ds .fa-ban {
  color: #e61d1d;
}
.StripeList td.col-score-stripe .normal {
  color: green;
}

/*noinspection CssUnusedSymbol*/
.StripeList td.col-score-stripe .elevated {
  color: orange;
}
.StripeList td.col-score-stripe .highest {
  color: red;
}
.StripeList td .fa-circle-check {
  color: #1eca1e;
}
.StripeList td .fa-circle-xmark {
  color: #e61d1d;
}
