/*body {*/
  /*margin: 0;*/
  /*padding: 0;*/
  /*font-family: sans-serif;*/
/*}*/

.OrderInfo {
    position:fixed;
    top: 0;
    right: 0;
    z-index:2;
    width:50%;
    height:100%;
    background-color:white;
}

.pz-btn input{
    margin-top: 2px;
}



.AdminCompactHeader {
    padding: 5px 0 5px 0;
    position: relative;
    z-index: 10;
}

.AdminCompactHeader .sync {
    position:fixed;
    right: 20px;
}

.AdminCompactHeader .sync .title {
    font-size:1.5rem;
}

.AdminCompactHeader .sync a {
    text-align: left;
}

.AdminCompactHeader .btn{
    margin-right: 5px;
}

.AdminCompactHeader select {
    width: 100px;
    float: right;
}

.AdminCompactHeader .pseudo-area {
    margin-left: 5px;
    margin-right: 5px;
    float: right;
}



.Slot {
    width: 75px;
}

.Slot .slot-etd{
    font-size: 9px;
}


.Address a, .Address a:hover, .Address a:active{
    color:#333333;
}



.TaskName button {
    margin-left: 5px;
}

.TaskName .opz-lock, .TaskName .opz-success, .TaskName .opz-default {
    cursor: pointer;
}


.opz-popover {
    width: 180px;
}

.opz-popover .popover-body {
    width: inherit;
    height: inherit;
    padding: 5px;

}
.opz-popover p {
    margin-bottom: 0;
}

.opz-popover button {
    width: 100%;
    margin-top:10px;
}

.opz-popover button.btn-default {
    color: #FFF;
    font-size: 11px;
    background-color: #777;
}
.opz-popover button.btn-default:hover {
    background-color: #555;
}



.SelectStatus select {
    width:80px;
    padding: 1px 2px 1px 2px;
    height: 20px;
    font-size: 12px;
}

div.SelectStatus  {
    margin-bottom: 0;
}



.Row .draggable {
    /*noinspection CssUnknownTarget*/
    background-image: url("/draggable3_25.png");
    background-repeat: no-repeat;
    height: 25px;
    width: 25px;
}

.Row .draggable.locked {
    filter: alpha(Opacity=25);
    opacity: 0.25;
}

.drag-select {
    width: 40px;
    text-align: center;
}

.Row td {
    padding-left: 5px;
}

.Row.updating {
    color:dimgrey;
}

/*.Row.error {*/
    /*animation: ERROR-BLINK 2s infinite;*/

/*}*/

/*@-webkit-keyframes ERROR-BLINK {*/
    /*0%, 49% {*/
    /*}*/
    /*50%, 100% {*/
        /*background-color: #e50000;*/
    /*}*/
/*}*/


tr.is-dragging-row {
    opacity: 0.5;
    background-color: white !important;;
}
tr.is-over-row,
tr.is-over-row td {
    background-color: LightGreen !important;

}

tr.Row.DeliveryRow.received  {
    background-color: lightgreen!important;
}

tr.Row.DeliveryRow.late_delivery_expected  {
    background-color: #f18043 !important;
}

tr.Row.DeliveryRow.late_delivered  {
    background-color: #f1723c !important;
}

/*noinspection CssUnusedSymbol*/
tr.Row.DeliveryRow.soon_delivery_expected  {
    background-color: #b982f6 !important;
}

/*noinspection CssUnusedSymbol*/
tr.Row.DeliveryRow.soon_delivered  {
    background-color: #8b6fd5 !important;
}

tr.Row.DeliveryRow.undeliveredclient  {
    background-color: lightcoral!important;
}

tr.Row.DeliveryRow.cross_dock_warning.cross_dock_warning  {
    background-color: red!important;
}
tr.Row.DeliveryRow.express_prio_warning.express_prio_warning,
tr.Row.DeliveryRow.express_prio_warning.express_prio_warning td,
tr.Row.DeliveryRow.dqp_courier_warning.dqp_courier_warning.dqp_courier_warning td:not(.slots)
{
    background-color: red!important;
}

@keyframes flashy {
from { background-color: red; }
to { background-color: white; }
}

/* https://www.developpez.net/forums/d1730383/webmasters-developpement-web/mise-page-css/faire-clignoter-div-css/ */
tr.Row.DeliveryRow.dqp_courier_warning.dqp_courier_warning.dqp_courier_warning.dqp_courier_warning td.slots,
tr.Row.DeliveryRow.dqp_courier_warning_soft.dqp_courier_warning_soft.dqp_courier_warning_soft.dqp_courier_warning_soft td.slots
{
    animation: flashy 2s infinite alternate;
}

tr.Row.DeliveryRow.late-list .Slot .eta-label {
    display: none;
}

tr.Row.PackingRow.vip_warning.vip_warning  {
  background: rgba(255, 0, 0, 0.25) !important;
}

tr.Row.PackingRow.venere_warning.venere_warning  {
  background: rgba(250, 241, 9, 0.2) !important;
}

.Row.canceled:not(.OrderRow)  {
    background-color: lightcoral!important;
}

.menu-actions .popover-body {
    padding: 0 2px 2px 2px;
    font-size: 11px;
    height: auto;
}

.menu-actions button {
    width: 100%;
    margin-bottom: 10px;
}


.Row .tags-collection .badge {
    margin-left: 2px;
    padding-left: 1px;
    padding-right: 1px;
    font-weight: normal;
    /*background-color: purple;*/
}
/*.Row .tags-collection .badge.weight {*/
.badge.weight {
    background-color: #8989e2;
}

/*.Row .tags-collection .badge.weight.heavy {*/
.badge.weight.heavy {
    background-color: #7D00DC;
}

/*.Row .tags-collection .badge.weight.heavy {*/
.badge.weight.b2c-undeliverable {
    color: orange;
    font-weight: bold;
}

/*.Row .tags-collection .badge.is-tr {*/
.badge.is-tr {
    background-color:   #604dff;
}

/*.Row .tags-collection .badge.is-ot {*/
.badge.is-ot {
    background-color:   #3a4570;
}

/*.Row .tags-collection .badge.is-pro {*/
.badge.is-pro {
    background-color: #ff884d;
}

.badge.goblast {
  background-color: cadetblue;
  color: white;
}

/*.Row .tags-collection .badge.prio {*/
.badge.prio {
    background-color: yellow;
    color: black;
}

/*.Row .tags-collection .badge.courier {*/
.badge.courier {
    background-color: yellow;
    color: black;
}

/*.Row .tags-collection .badge.is-super-venere {*/
.badge.is-super-venere {
    background-color: yellow;
    color: black;
}

/*.Row .tags-collection .badge.missing-bags {*/
.badge.missing-bags {
    background-color: #cd4ce1;
    color: white;
}

/*.Row .tags-collection .badge.redelivering {*/
.badge.redelivering {
    background-color: springgreen;
    color: black;
}

.badge.lgep {
    background-color: black;
    color: white;
}

.badge.frichti {
    background-color: #faf190;
    color: black;
}

.badge.ubereats {
  background-color: #06C167;
  color: black;
}

.badge.uberdirect {
    background-color: black;
    color: white;
}

.badge.uberdirect.error {
    background: repeating-linear-gradient(
        to bottom,
        black,
        black 2px,
        red,
        red 4px
        );
    color: white;
}

/*.Row .tags-collection .badge.is-staff {*/
.badge.is-staff {
    background-color:   #604dff;
    color: #ffffff;
}

/*.Row .tags-collection .badge.is-exchange {*/
.badge.is-exchange {
    background-color: #4dadff;
    color: #ffffff;
}

/*.Row .tags-collection .badge.is-oneprep {*/
.badge.is-oneprep {
    background-color: #91ff00;
    color: #000;
}

/*.Row .tags-collection .badge.is-fraud {*/
.badge.is-fraud {
    background-color: #000000;
    color: #ffffff;
}

/*.Row .tags-collection .badge.loading-inprogress {*/
.badge.loading-inprogress {
    background-color: indianred;
    color: #ffffff;
}

/*.Row .tags-collection .badge.loading-done {*/
.badge.loading-done {
    background-color: limegreen ;
    color: #ffffff;
}

.Row .frozen-icon {
  color: #01c1ff;
  margin-left: 2px;
}
.Row .frozen-icon.doing {
  color: orangered;
  cursor: pointer;
}
.Row .frozen-icon.done {
  color: #02c502;
  cursor: pointer;
}

.Late {
    width: 80px;
}

.Late button:first-child {
    margin-right: 5px;
}


.TransitArea {
    height: 19px;
    min-width: 5px;
}
.TransitArea .transit_area,
.TransitArea .transit_subarea {
  cursor: pointer;
}
.TransitArea .transit_area:hover,
.TransitArea .transit_subarea:hover {
  text-decoration: underline;
}



.Dock table {
    margin-bottom: 10px;
}

.Dock thead tr {
    background-color: lightyellow;
}

.Dock.loaded:not(.driver_hard):not(.stuart):not(.cross_dock):not(.uber):not(.canceled-external-job) thead tr {
    background: repeating-linear-gradient(
            45deg,
            lightyellow,
            lightyellow 10px,
            #ffffb2 10px,
            #ffffb2 20px
    );

}

.Dock.driver_hard thead tr  {
    background-color: rgba(255, 165, 0, 0.44);
}

.DockList.deliveries-todo .Dock.locked:not(.stuart):not(.uber) thead tr:first-child  {
    background-color: lightgreen;
}

.DockList.deliveries-todo .Dock.canceled-external-job thead tr:first-child  {
    background-color: indianred;
}

.Dock {
    padding:0 5px 0 0;
}

.Dock thead form {
    margin-top: -2px;
    float:right;
    min-width:35%;
    max-width:60%;
    text-align:right;
}

/*.Dock.driver_hard thead form {*/
    /*width:50%;*/
/*}*/

/*.Dock.locked thead form {*/
    /*width: 50%;*/
/*}*/



.Dock thead form.updating {
    color:Lightgrey;
}

.Dock thead tr.subheader {
    font-size: 11px;
    font-weight: 300;
    background-color: white;
    height: 13px;
}

.Dock select.form-control {
    padding: 1px 2px 1px 2px;
    height: 20px;
    font-size: 11px;
    width: 100px;
}

.Dock .form-group  {
    height: 20px;
    margin-bottom: 0;
}

.Dock .last-run {
    margin-left: 5px;
}


/*noinspection CssUnusedSymbol*/
.Dock .stuart-running, .Dock .uber-running {
    padding: 0 5px 0 5px;
    font-size: 11px;
    font-weight: normal;
}

.Dock .btn {
    margin-left: 5px;
    padding: 0 5px 0 5px;
    font-size: 11px;
}

.Dock i {
    margin-left: 2px;
}

.Dock .DefaultDropZone td {
    text-align: center;
}

.Dock .draggable {
    /*noinspection CssUnknownTarget*/
    background-image: url("/draggable3_25.png");
    background-repeat: no-repeat;
    height: 25px;
    width: 25px;
}
.Dock .draggable.locked {
    filter: alpha(Opacity=25);
    opacity: 0.25;
}


.Dock.is-dragging-dock {
    opacity: 0.5;
    background-color: white !important;
}

.Dock.is-over-dock  tr {
    background-color: LightGreen !important;
}

.Dock .first-cmd {
    display: none;
}

.Dock .express {
    display: initial;
}

.Dock .OrderPseudoZoneName {
    display: none;
}

.Dispatch .Dock .link_admin {
    color: black;
    font-size: 1.2em;
    margin-left: 15px;
}
.Dispatch .Dock thead form {
  min-width: initial
}

@keyframes redFlashy {
    from { background-color: red; }
    to { background-color: white; }
    }

.Delay-time-take-bags {
    animation: redFlashy 1s infinite alternate;
}

@keyframes orangeFlashy {
    from { background-color: rgba(255, 128, 0, 0.732); }
    to { background-color: white; }
    }

.Limit-time-take-bags {
    animation: orangeFlashy 2s infinite alternate;
}



.define-loaded-label {
    margin-left: 5px;
}

.define-loading-label {
    margin-left: 5px;
}


.drivers {
    margin: 0 0 0 0;
}

.drivers .panel {
    margin-bottom: 10px;
}

.drivers-list {
    display: flex;
    flex-flow: row wrap;

    padding: 5px 5px 0 5px !important;
    background-color: #cDcDcD;
}

.drivers-list .DriverState {
    /*float: left;*/
    padding: 2px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 11px;
}
.drivers-list .DriverState.launchpad-default {
    background-color: #FFFFFF;
}

.drivers-list .DriverState.launchpad-basket {
    background-color: #a5aed1;
}



.drivers-list .DriverBurger {
    /*float: left;*/
    padding: 0 5px 0 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 11px;
    font-weight: bold;
    height: 24px;
}



.driver-actions {
    text-align: center;
}

.driver-actions .popover-body {
    width: 180px;
}
.driver-actions .popover-body, .driver-actions button {
    padding: 0 2px 2px 2px;
    font-size: 11px;
}



.driver-actions h5 {
    margin-top: 2px;
    margin-bottom: 2px;
    padding: 2px 0 2px 0;
    font-weight: bold;
    background-color: lightgoldenrodyellow;
    font-size: 14px;
    width: 170px;

}

.driver-actions input {
    margin: 0 5px 0 5px;
    width: 60px;
}

/*.driver-actions .normal-case {*/
/*    width: 130px;*/
/*}*/

/*.driver-actions-present, .driver-actions-absent {*/
/*    width: 130px;*/
/*}*/
.driver-actions-present button, .driver-actions-absent button{
    margin-bottom: 5px;
    margin-top: 5px;
    width: 170px;
}

/*.driver-actions-present {*/
/*    width: 130px;*/
/*}*/

.DriverState.noplan button.btn-primary {
    background-color: red;
    border-color: red;
}

.PackingList {

}

.PackingList .title-right {
    font-size: 14px;
    color: #333333;
}

.PackingList.waiting-products-list .SelectStatus select{
    width: 50px;
}

.ReadyToGoButton {
    margin-left: 5px;
}


.PackingList .day-label {
    font-weight: bold;

}


.blocked-list tr.Row {
    background: rgba(255, 0, 0, 0.44) !important;
}
.blocked-list .Row.updating {
    color:inherit;
}

.PackingNowList .DefaultDropZone {
    font-size: 1px;
    height: 15px;
}

.PackingNowList .title-right {
    font-size: 14px;
    color: #333333;
}

.PackingNowList .DefaultDropZone td{
    height: 15px;
}


.late-list tr.Row {
    background: rgba(255, 165, 0, 0.44) !important;
}

.late-list .Row.updating {
    color:inherit;
}

.late-list .Row.updating  .Late button {
    background-color: #cDcDcD;
}


.error-list h4 {
    background-color: red;
    color: white;
    font-weight: bold;
}

.DeliveryRow i.no_finalized {
  color: #c9302c;
  margin-left: 1px;
}



.NextTaskForm {
    font-size: 12px;
}

.NextTaskForm.staff-active {
    font-weight: bold;
}

.NextTaskForm .is-interim {
  color:red;
}


.NextTaskForm.staff-trying-period > .name a {
  color: black !important;
}

.NextTaskForm.staff-stopped > .name a {
  color: darkred !important;
}



.NextTaskForm select {
    min-width: 150px;
    max-width: 150px;
    padding: 1px 2px 1px 2px;
    height: 20px;
    font-size: 12px;
}

.NextTaskForm.staff-active form {
    font-weight: normal;
}


.table-hover tbody tr:hover.NextTaskForm td, .table-hover tbody tr:hover th {
    background-color: lightgreen;
}

.Assign th.sortable {
    -webkit-text-decoration: underline dotted;
            text-decoration: underline dotted;
    cursor: pointer;
}

.Assign th.sort-current {
    text-decoration: underline;
    cursor: pointer;
}

.perm-button {
    padding: 1px;
    margin: 0 1px;
    position: relative;
    z-index: 1;
}
.perm-button.loading {
  opacity: 0.6;
}
.perm-button.loading:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.3;
  left: 0;
  top: 0;
  border-radius: 3px;
  z-index: 99;
}
.grant_overly_trigger {
  cursor: pointer;
}

#grant-popover-trigger-click {
  max-width: 650px;
}
#grant-popover-trigger-click .popover-body {
  width: 650px;
  height: auto;
}
#grant-popover-trigger-click .perm-button {
  margin-bottom: 8px;
  display: block;
  float: left;
}
#grant-popover-trigger-click .perm-button.new_line {
  clear: left;
}
.nb-active-sub-grant {
  padding-left: 4px;
  font-size: 9px;
  line-height: 18px;
  vertical-align: top;
}

.tags-collection .badge.is-test {
    background-color: #F47C3C;
}
.prep_cph_by_time,
.prep_iph_by_time,
.prep_tad_by_time,
.prep_tap_by_time {
  font-size: 8px;
}
.prep_cph_by_time > span:before,
.prep_iph_by_time > span:before,
.prep_tad_by_time > span:before,
.prep_tap_by_time > span:before {
  content: " / ";
}
.prep_cph_by_time span:first-child:before,
.prep_iph_by_time span:first-child:before,
.prep_tad_by_time span:first-child:before,
.prep_tap_by_time span:first-child:before {
  content: initial;
}
.prep_cph_by_time em,
.prep_iph_by_time em,
.prep_tad_by_time em,
.prep_tap_by_time em {
  font-style: normal;
  color: grey;
}
/*
.AssignNavBar.nav {
    margin-top: 5px;
}
.AssignNavBar li a {
    padding: 2px 5px;
}

.AssignNavBar.nav-pills > li.active a {
    background-color: #BBBBBB;
    color: #333333;
}
.AssignNavBar.nav-pills > li a {
    color: #333333;
}

*/


span.badge.success {
  background-color: green;
}
span.badge.error {
  background-color: red
}
.DepositDriverReturn .form-group {
  margin-bottom:5px;
}
.deposit-flex {
  display: flex!important;
  height: 75px;
  float: left;
}

.deposit-center {
  align-self: center;
}
.deposit-qty input {
  display: inline-block;
  width: auto;
}
.deposit-qty span {
  display: inline-block;
  margin-left: 5px;
}
.cancel_received_disabled button {
  cursor: not-allowed!important;
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
  box-shadow: none;
  opacity: .65;
}

.Paginator { text-align:center; margin: 10px 0;}

.DepositModalDrivers .modal-dialog,
.DepositModalDriver .modal-dialog {
  width: 60vw;
  min-width: 600px;
  max-width: initial;
}
.DepositModalDriver .form-horizontal .control-label {
  text-align: left;
}
.DepositModalDrivers .table td,
.DepositModalDriver .table td {
  vertical-align: middle;
}
.DepositDrivers,
.DepositDriver {
  min-height: 200px;
  position: relative;
}
.DepositDrivers .loading,
.DepositDriver .loading {
  position:absolute;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}
.DepositDrivers .loading .loading-back,
.DepositDriver .loading .loading-back {
  position:absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.7;
}
.DepositDrivers .loading .loading-content,
.DepositDriver .loading .loading-content {
  position: absolute;
  top: 100px;
  left: 50%;
  max-height: 100vh;
  transform: translate(-50%, -50%);
}
.DepositDriverHistory .table-condensed td, th { padding: 0 2px!important; }
.DepositDriver .badge { margin-right: 2px; }
.DepositDriver .badge.deposit-cancel { background-color: #a800ab; color: white; }
.DepositDriver .badge.deposit-fix { background-color: red; color: white; }
.DepositDriver .badge.deposit-user { background-color: orange; color: black; }
.DepositDriver .badge.deposit-driver { background-color: #c1f1a8; color: black; }
.DepositDriver .badge.deposit-fix-partial { border:1px solid #0097A7; background-color: #E0F7FA; color: black; }
.DepositDriver .badge.deposit-fix-total { background-color: #0097A7; color: white; }
.DepositDriver .badge.deposit-canceled-partial { border: 1px solid #b416e8; background-color: #f3d0ff; color: black; }
.DepositDriver .badge.deposit-canceled-total { background-color: #b416e8; color: white; }
.deposit-popover { max-width: 500px!important; }
.deposit-popover .popover-body { width: 500px; height: auto; }
#deposit-fix-form .info-quantity { padding-left: 0; }
#deposit-fix-form .info-quantity .control-label { text-align: left; }
#deposit-detail-form { max-width: 600px!important; }
#deposit-detail-form .popover-body { width: 600px; height: auto; }




.Dispatch .DeliveryRow .TransitArea {
  min-width: 110px;
}
.Dispatch .col-huge-2 .DeliveryRow .TransitArea,
.Dispatch .col-huge-2virgule4 .DeliveryRow .TransitArea {
  min-width: 30px;
}
.Dispatch .DeliveryRow.is_moving {
  opacity: 0.7;
}
.Dispatch .DeliveryRow .draggable .CancelDeliveryDrop {
  margin: 25px 0 0 0;
}
.Dispatch .DeliveryRow .more-icon {
  display: block;
}
.Dispatch .DeliveryRow .moving-icon {
  color: #ef9647;
}
.Dispatch .DeliveryRow.is-dragging-row > td {
  background-color: rgb(253 229 60 / 60%)!important;
}
.Dispatch .DeliveryRow .prep_progress {
  white-space: nowrap;
  margin-left: 2px;
}
.Dispatch .DeliveryRow .prep_progress i {
  margin-left: 0;
}

.Dispatch .DeliveryRow.to-check,
.Dispatch .DeliveryRow.waiting-products {
  background-color: #e7ccff;
}

.Dispatch .DeliveryRow .TransitArea .not_loaded_on_trolley {
  color: red;
}

.Dispatch .DeliveriesToDispatch table {
  table-layout: fixed;
}

/* depend de table-layout: fixed; */
.Dispatch .Row  .slots {
  white-space: nowrap;
  width: 80px;
}

.Dispatch .Row  .btn-stuart {
  white-space: nowrap;
  width: 50px;
  padding-left: 5px !important;
}

.Dispatch .Row  .zonage {
  min-width: 25px;
  max-width: 90px;
}

.Dispatch .Row .bags {
  width: 25px;
  white-space: nowrap;
}

.Dispatch .Row.type-dock .slots {
  width: 80px;
  white-space: nowrap;
}

.Dispatch .Row.type-dock  .btn-stuart {
  white-space: nowrap;
  width: 50px;
  padding-left: 5px !important;
}

.Dispatch .DeliveryRow.address-invalid td.address {
  background-color: #ff0101;
}

@media (max-width:1899px) {
  .Dispatch .Row td.status, .Dispatch .Row td.client-name {
    display: none;
  }
}

@media (min-width:1899px) {
  .Dispatch .Row td.status, .Dispatch .Row td.client-name {
    display: table-cell;
  }
  .Dispatch .Row td.status {
    width: 90px;
  }
}


.DriverLogs ul {
    padding-left: 0;
}

.DriverLogs li {
    margin-left: 20px;

}
.DriverLogs li:hover {
    background-color: lightgray;
}

.DriverLogs .presence-title {
    padding: 2px 0 2px 2px;
    border: 1px solid lightgray;
    font-size: 13px;
    height: 25px;
}
.DriverLogs .presence-title.running {
    background-color: lightgreen;
}

.DriverLogs .presence-title.updating {
    color: grey;
    opacity: 0.6;
    cursor: progress;
}


.DriverLogs .presence-title:hover {
    background-color: lightgray;
}

.DriverLogs .presence-title .edit input {
    height: 18px;
    padding: 1px 0 1px 0;
    width: 50px;
    margin-right: 5px;
}

.DriverLogs .presence-title .edit button {
    margin-right: 5px;
}
.ExtraCostDelete button {
  /*noinspection CssNonIntegerLengthInPixels*/
  line-height: 1.3px;
  font-size: 9px;
}


.ais-SearchBox input.ais-SearchBox-input {
    padding-left: 22px;
    font-size: 18px;
    height: 22px;
}

.ais-Menu {
    /*border: 1px solid #CCCCCC;*/

}

.ais-Menu .ais-Menu-showMore {
    background-color: #FFFFFF;
    text-decoration: underline;
    color: #333;
    font-size: 11px;
    margin-top: 0;
}


.ais-InstantSearch__root tr.hit td {
    cursor: pointer !important;
}







.UserOrder h3{
    text-align: center;

}

.UserOrder h3 .badge {
    margin-left: 5px;
    padding: 2px 5px;
}

.UserOrder h6.subtitle {
    font-size: 12px;
    text-align: center;
}
.UserOrder h4.subtitle {
    text-align: center;
    background-color: inherit;
    margin-top: -5px;
}

.modal-body {
    padding: 5px 10px;
}

.modal-body h4 {
    background-color: white;
    text-align: center;
    font-weight: bold;
}

.UserOrder .nav-tabs a{
    padding: 5px;
}

.UserFraudDetail .popover-body {
  width: 400px;
  height: auto;
}

.OrderDetail .order-nav .nav-item {
  background-color: #f5f5f5;
}
.OrderDetail .order-nav .nav-item:first-child {
  border-radius: 5px 0 0 5px;
  overflow: hidden;
}
.OrderDetail .order-nav .nav-item:last-child {
  border-radius: 0 5px 5px 0;
  overflow: hidden;
}
.OrderDetail .order-nav .nav-item.active {
  background-color: #e6e6e6;
}
.OrderDetail .order-nav a {
  color: #737373;
}
.OrderDetail .order-nav .nav-item.active a {
  color: black;
}

.OrderDetail tr:first-child td, .OrderDetail tr:first-child th {
    border-top: 0;
}

.OrderDetail .OrderSummary tr td:first-child,
.OrderDetail .OrderFinancial tr td:first-child,
.OrderDetail .OrderActions tr td:first-child,
.OrderDetail .OrderPackingDetail tr td:first-child,
.OrderDetail .OrdersLinked tr td:first-child,
.OrderDetail .OrderDeliveryDetail tr td:first-child,
.OrderDetail .OrderProductsEmpty tr td:first-child {
    width: 50%;
}

.OrderDetail .panel-heading-sm{
    height: 22px;
    padding: 2px 5px;
}

.OrderDetail .panel-body-sm{
    padding: 5px 5px;
}

.OrderDetail .panel-body-sm h4 {
    margin-top: 5px;
    background-color: #FFFFFF;
    text-align: left;
}

.empty-info {
    color: #cccccc;
}

.invalidAddress {
    background: lightpink;
}

.tooltip-order-stat-driver .tooltip-inner {
  text-align: left;
  max-width: 500px;
}
.tooltip-order-stat-driver ul {
  list-style: none;
  padding-left: 2px;
}
.tooltip-order-stat-driver ul li ul {
  padding-left: 10px;
}

.ModalStatDriver .modal-dialog {
  width: 70vw;
}

.PopoverStatDriverDetail {
  width: 40vw;
  max-width: initial !important;
}
.PopoverStatDriverDetail .popover-body {
  height: auto;
  min-height: 120px;
  max-height: 70vh;
  width: 100%;
}



.OrderProduct .updating {
    color:grey;
}

.OrderProduct .badge {
    margin-left: 5px;
}
.OrderProduct .badge:first-of-type {
    margin-left: 0;
}
.duplicate-order-dialog{
    width: 50%!important;
}
.OrderSummary .badge{
    margin-right: 5px;
}
.OrderPrepZoneDisplay > span:not(.OrderPrepZoneAction):before {
  content: ', ';
}
.OrderPrepZoneDisplay > span:first-child:before {
  content: '';
}
.OrderPrepZoneDisplay > span:hover {
  color: black;
  cursor: pointer;
}
.OrderPrepZoneDisplay[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

.OrderCrossDocking .current-order {
  font-weight: bold;
}

.OrderCrossDocking tr:hover {
  cursor: pointer;
}

.OrderCrossDocking tr.current-order:hover, .OrderCrossDocking tr.docking-title:hover {
  cursor: auto;
}

#gift_message_modal textarea {
  height: 150px;
}



.OrderList .current {
    font-weight: bold;

}

[id^="tooltip-stripe-infos"] ul {
  list-style: none;
  padding:0;
  margin:0;
}
.popover-body { width :290px; height: 120px; }

.StripeList thead th,
.StripeList tbody td.col-date { white-space: nowrap; }
.StripeList .col-order-id,
.StripeList .col-amount,
.StripeList .col-score-stripe,
.StripeList .col-infos,
.StripeList .col-3ds { text-align: center; }
.StripeList .ch_succeeded td,
.StripeList .pi_succeeded td {
  background-color: #e0ffe0;
}
.StripeList .col-infos { cursor: pointer; }
.StripeList td.col-3ds .fa-lock {
  color: #1eca1e;
}
.StripeList td.col-3ds .fa-ban {
  color: #e61d1d;
}
.StripeList td.col-score-stripe .normal {
  color: green;
}

/*noinspection CssUnusedSymbol*/
.StripeList td.col-score-stripe .elevated {
  color: orange;
}
.StripeList td.col-score-stripe .highest {
  color: red;
}
.StripeList td .fa-circle-check {
  color: #1eca1e;
}
.StripeList td .fa-circle-xmark {
  color: #e61d1d;
}

.DepositUser {
  padding: 20px 0;
}
.DepositUser .DepositUserCurrent td { vertical-align: middle; }
#deposit-detail-form .badge.deposit-cancel { background-color: #a800ab; color: white; }
#deposit-detail-form .badge.deposit-fix { background-color: red; color: white; }
#deposit-detail-form .badge.deposit-user { background-color: orange; color: black; }
#deposit-detail-form .badge.deposit-driver { background-color: #c1f1a8; color: black; }
#deposit-detail-form .badge.deposit-fix-partial { border:1px solid #0097A7; background-color: #E0F7FA; color: black; }
#deposit-detail-form .badge.deposit-fix-total { background-color: #0097A7; color: white; }
#deposit-detail-form .badge.deposit-canceled-partial { border:1px solid #b416e8; background-color: #f3d0ff; color: black; }
#deposit-detail-form .badge.deposit-canceled-total { background-color: #b416e8; color: white; }


.CreditList table tr td,
.CreditList table tr th{
  padding: 2px 5px !important;
}
.CreditList table tr td.col-order-id {
  cursor: pointer;
  color:#337ab7;
}
.Support {
    margin-top: 20px
}
.super_checkbox.checkbox {
  margin:0;
}
.super_checkbox.checkbox label {
  padding-left: 0;
}
.super_checkbox input[type="checkbox"] {
  display: none;
}
.super_checkbox input[type="checkbox"] + .checkbox_checked,
.super_checkbox input[type="checkbox"] + .checkbox_checked + .checkbox_not_checked {
    padding: 1px 5px;
    font-size: 10px;
    line-height: 1.5;
    border-radius: 3px 0 0 3px;
}
.super_checkbox input[type="checkbox"] + .checkbox_checked {
  background-color: #fff;
  border-color: #ccc;
  color: #333333
}
.super_checkbox input[type="checkbox"] + .checkbox_checked + .checkbox_not_checked {
  color: #fff;
  background-color: #286090;
  border-color: #122b40;
  border-radius: 0 3px 3px 0;
}
.super_checkbox input[type="checkbox"]:checked + .checkbox_checked {
  color: #fff;
  background-color: #286090;
  border-color: #122b40;
}
.super_checkbox input[type="checkbox"]:checked + .checkbox_checked + .checkbox_not_checked {
  background-color: #fff;
  border-color: #ccc;
  color: #333333
}
.display-linebreak {
  white-space: pre-line;
}

.Dispatch .Dock {
  border: 1px solid lightgrey;
  padding: 0;
}

.Dispatch .Dock .draggable.locked {
  filter: alpha(Opacity=25);
  opacity: 0.25;
}

.Dispatch .Docks .Dock.driver_hard .DockTitle .DockReturnSetting {
  width: 70px;
}
.Dispatch .Docks .Dock.driver_hard .DockTitle .DockReturnSetting select{
  width: 70px;
}

.Dispatch .Dock .dock_top .DockTitle .dock_name {
  flex-grow: 8;
}
.Dispatch .Dock .dock_top .DockTitle form {
  order: 2;
  justify-content: end;
  flex-grow: 0;
}

.DockList.deliveries-todo .Dock.locked:not(.stuart):not(.uber) thead tr:first-child  {
  background-color: lightgreen;
}

.Dock.stuart thead tr:first-child {
  background-color: #0192ff;
  color: white;
}

.Dock.canceled-external-job thead tr:first-child {
  background-color: indianred;
}

.Dock.uber:not(.canceled-external-job) thead tr:first-child {
  background-color: black;
  color: white;
}

.Dock {
  padding:0 5px 0 0;
}



.Dock .dock_top form.updating {
  color:Lightgrey;
}

.Dock .dock_top .subheader {
  font-size: 11px;
  font-weight: 300;
  background-color: white;
  height: 13px;
}

.Dock select.form-control {
  padding: 1px 2px 1px 2px;
  height: 20px;
  font-size: 11px;
  width: 100px;
}

.Dock .form-group  {
  height: 20px;
  margin-bottom: 0;
}

.Dock .last-run {
  margin-left: 5px;
}

.Dock .btn {
  margin-left: 5px;
  padding: 0 5px 0 5px;
  font-size: 11px;
}

.Dock i {
  margin-left: 2px;
}

.Dock .DefaultDropZone td {
  text-align: center;
}

.Dock .draggable {
}
.Dock .draggable.locked {
  filter: alpha(Opacity=25);
  opacity: 0.25;
}

.Dispatch .Dock .DefaultDropZone {
  background-color: transparent;
  height: 100%;
}


.Dock.is-dragging-dock {
  opacity: 0.5;
  background-color: white !important;
}

.Dock.is-over-dock  {
  /*background-color: LightGreen !important;*/
  border-color: LightGreen;
  border-width: 2px;
}

.Dock .first-cmd {
  display: none;
}

.Dock .express {
  display: initial;
}

.Dock .OrderPseudoZoneName {
  display: none;
}

.Dock .link_admin {
  color: black;
  font-size: 1.2em;
  margin-left: 1.5em;
}



.define-loaded-label {
    margin-left: 5px;
}

.define-loading-label {
    margin-left: 5px;
}

.DriverQueue {
  margin-left: 2px;
  vertical-align: middle !important;
}
.DriverQueue.badge-car {
  background-color: #8c8cff;
}
.DriverQueue.badge-s50 {
  background-color: #ff4e4e;
}
.DriverQueue.badge-s125 {
  background-color: #00b400;
}
.DriverQueue.badge-selec {
  background-color: yellow;
  color: black;
}
.DriverQueue.badge-bike {
  background-color: purple;
}
.DriverQueue.badge-truck {
  background-color: lightseagreen;
}


.drivers {
    margin: 0 0 0 0;
}

.drivers .panel {
    margin-bottom: 10px;
}

.drivers-list {
    display: flex;
    flex-flow: row wrap;

    padding: 5px 5px 0 5px !important;
    background-color: #cDcDcD;
}

.drivers-list .DriverState {
    /*float: left;*/
    padding: 2px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 11px;
}

.drivers-list .DriverBurger {
    /*float: left;*/
    padding: 0 5px 0 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 11px;
    font-weight: bold;
    height: 24px;
}



.driver-actions {
    text-align: center;
}

.driver-actions .popover-body {
    width: 180px;
}
.driver-actions .popover-body, .driver-actions button {
    padding: 0 2px 2px 2px;
    font-size: 11px;
}



.driver-actions h5 {
    margin-top: 2px;
    margin-bottom: 2px;
    padding: 2px 0 2px 0;
    font-weight: bold;
    background-color: lightgoldenrodyellow;
    font-size: 14px;
    width: 170px;

}

.driver-actions input {
    margin: 0 5px 0 5px;
    width: 60px;
}

/*.driver-actions .normal-case {*/
/*    width: 130px;*/
/*}*/

/*.driver-actions-present, .driver-actions-absent {*/
/*    width: 130px;*/
/*}*/
.driver-actions-present button, .driver-actions-absent button{
    margin-bottom: 5px;
    margin-top: 5px;
    width: 170px;
}

/*.driver-actions-present {*/
/*    width: 130px;*/
/*}*/

.DriverState.noplan button.btn-primary {
    background-color: red;
    border-color: red;
}

.DriverEndOfShift { margin-left: 2px; }

.DepositDriverReturn .form-group {
  margin-bottom:5px;
}
.deposit-flex {
  display: flex!important;
  height: 75px;
  float: left;
}

.deposit-center {
  align-self: center;
}
.deposit-qty input {
  display: inline-block;
  width: auto;
}
.deposit-qty span {
  display: inline-block;
  margin-left: 5px;
}
.cancel_received_disabled button {
  cursor: not-allowed!important;
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
  box-shadow: none;
  opacity: .65;
}

.DepositOrderRow {
  display: flex;
  align-items: center;
  padding: 1rem 1rem;
  margin-bottom: 0;
  border-bottom: 1px solid lightgray;
}

.DepositOrderRow p, .DepositOrderRow a {
  margin-bottom: 0;
  margin-right: 2rem;
}

.DepositModalDrivers .modal-dialog,
.DepositModalDriver .modal-dialog {
  width: 60vw;
  min-width: 600px;
  max-width: initial;
}
.DepositModalDriver .form-horizontal .control-label {
  text-align: left;
}
.DepositModalDrivers .table td,
.DepositModalDriver .table td {
  vertical-align: middle;
}
.DepositDrivers,
.DepositDriver {
  min-height: 200px;
  position: relative;
}
.DepositDrivers .loading,
.DepositDriver .loading {
  position:absolute;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}
.DepositDrivers .loading .loading-back,
.DepositDriver .loading .loading-back {
  position:absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.7;
}
.DepositDrivers .loading .loading-content,
.DepositDriver .loading .loading-content {
  position: absolute;
  top: 100px;
  left: 50%;
  max-height: 100vh;
  transform: translate(-50%, -50%);
}
.DepositDriverHistory .table-condensed td, th { padding: 0 2px!important; }
.DepositDriver .badge { margin-right: 2px; }
.DepositDriver .badge.deposit-cancel { background-color: #a800ab; color: white; }
.DepositDriver .badge.deposit-fix { background-color: red; color: white; }
.DepositDriver .badge.deposit-user { background-color: orange; color: black; }
.DepositDriver .badge.deposit-driver { background-color: #c1f1a8; color: black; }
.DepositDriver .badge.deposit-fix-partial { border:1px solid #0097A7; background-color: #E0F7FA; color: black; }
.DepositDriver .badge.deposit-fix-total { background-color: #0097A7; color: white; }
.DepositDriver .badge.deposit-canceled-partial { border: 1px solid #b416e8; background-color: #f3d0ff; color: black; }
.DepositDriver .badge.deposit-canceled-total { background-color: #b416e8; color: white; }
.deposit-popover { max-width: 500px!important; }
.deposit-popover .popover-body { width: 500px; height: auto; }
#deposit-fix-form .info-quantity { padding-left: 0; }
#deposit-fix-form .info-quantity .control-label { text-align: left; }
#deposit-detail-form { max-width: 600px!important; }
#deposit-detail-form .popover-body { width: 600px; height: auto; }






/*noinspection CssUnusedSymbol*/
.ExtraDelay, .ExpressExtraDelay, .ExpressPrioExtraDelay, .CourierExtraDelay, .RainBonus,
.FrichtiExtraDelay, .FrichtiExpressPrioExtraDelay {
    font-size: 11px;
    text-align: right;
    padding-right: 5px;
}
.ExtraDelay{
    width: 110px;
}
.ExpressExtraDelay{
    width: 170px;
}
.ExpressPrioExtraDelay{
    width: 220px;
}
.CourierExtraDelay{
    width: 120px;
}
.RainBonus.RainBonus{
    width: 150px;
}

/*noinspection CssUnusedSymbol*/
.FrichtiExtraDelay{
    width: 170px;
}

/*noinspection CssUnusedSymbol*/
.FrichtiExpressPrioExtraDelay{
    width: 270px;
}

.ExtraDelay input, .ExpressExtraDelay input, .ExpressPrioExtraDelay input, .CourierExtraDelay input,
.FrichtiExpressPrioExtraDelay input, .FrichtiExtraDelay input {
    width: 50px !important;
    padding: 2px 2px 2px 2px;
    height: 18px;
    font-size: 12px;
}
.ExpressPrioExtraDelay input[type="checkbox"],
.CourierExtraDelay input[type="checkbox"],
.FrichtiExpressPrioExtraDelay input[type="checkbox"] {
    width: 18px !important;
}

/*noinspection CssUnusedSymbol*/
.ExtraDelay.updating,
.ExpressExtraDelay.updating,
.ExpressPrioExtraDelay.updating,
.CourierExtraDelay.updating,
.FrichtiExtraDelay.updating,
.FrichtiExpressPrioExtraDelay.updating,
.RainBonus.updating {
    color: grey;
}

.dqp-header h4 div {
    display: inline-flex;
}



.ToDispatchFilter {
    display: flex;
    justify-content: space-around;
    margin-bottom: 5px;
}

.Dispatch h4 {
  background-color: #f5defd;
}



body .Dispatch {
  font-size: 11px;
}
.Dispatch .Deliveries {
  max-height: calc(100vh - 35px);
  /* overflow-y: auto; */
  padding: 0 0 0 2px!important;
}

.Dispatch .Docks {
  max-height: calc(100vh - 35px);
  overflow-y: auto;
  padding: 0 2px 0 5px!important;
}

.Dispatch .Docks .DockList h4.row {
  display: block;
}

@media(min-width: 1200px) {
    .OptimizeDockModal .modal-dialog.modal-xl {
        width: 80vw;
    }
}
.route-compare-container table, h2 {
    margin: 30px 0;
}
.route-compare-container tr {
    border-bottom: solid 1px #eee;
}

.route-compare-container tr:nth-child(even) {
    background: #efefef;
}

.route-compare-container tr td {
    padding: 5px 10px;
}

.route-compare-container tr td:last-child {
    --padding-left: 40px;
}


.PrioItem {
    max-width: 50%;
    min-width: 400px;
}

.PrioItem .prio-label {
    padding: 5px;
    /*border: 1px #333333 solid;*/
    width: 100%;
}

.PrioItem:not(.disabled) .prio-name {
    font-weight: bold;
}

.PrioItem .insert-zone {
    height: 5px;
    font-size: 0;
    width: 100%;
}

.PrioItem.updating {
    color:dimgrey;
}

.PrioItem .late-title {
    color:red;
    font-weight: bold;
}


.PrioItem.is-dragging-row {
    opacity: 0.5;
    background-color: white !important;;
}
.PrioItem.is-over-row .insert-zone {
    background-color: LightGreen !important;
}

.PrioItem .div-alert {
    margin: 5px 0;
}

.order-row.done {
  background-color: #05a356;
  color: white;
}

.order-row.prepared {
  background-color: #2ff896;
}

.order-row.preparing {
  background-color: #c3fde0;
}

.uber-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;;
  background-color: lightyellow;
  margin: 1rem 0 0.5rem 0; 
}

.uber-section-header > h4 {
  margin: 0;
}

.uber-status-scheduled {
  background-color: honeydew;
  padding: 0 0.2rem;
}

.uber-status-en_route_to_pickup {
  background-color: lightgreen;
  padding: 0 0.2rem;
}

.uber-status-arrived_at_pickup {
  background-color: mediumseagreen;
  padding: 0 0.2rem;
  color: white;
}

.uber-status-en_route_to_dropoff {
  background-color: green;
  color: white;
  padding: 0 0.2rem;
}

.uber-status-completed {
  background-color: darkgreen;
  color: white;
  padding: 0 0.2rem;
}

.uber-status-failed {
  background-color: #d9534f;
  color: white;
}

body {
    font-size: 12px;
}
h1,
.h1 {
  font-size: 36px;
}
h2,
.h2 {
  font-size: 30px;
}
h3,
.h3 {
  font-size: 24px;
}
h4,
.h4 {
  font-size: 18px;
}
h5,
.h5 {
  font-size: 14px;
}
h6,
.h6 {
  font-size: 12px;
}

h4 {
    font-size: 18px;
    background-color: LightYellow;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
    color: #337ab7;
    text-decoration: none;
    cursor: pointer;
}

.table-condensed td, th {
    padding: 2px !important;
}
.table-sm td, th {
    padding: 2px!important;
}

body button.btn-xs, body a.btn-xs,
body .btn-group-xs > .btn {
    font-size: 10px;
}

.notifications-tc {
    margin-top: 40px;
}

.App h1.loading {
    font-size: 60px;
    color: darkgray;
}

/* CUSTOM BOOTSTRAP */
a {
    color: #337ab7;
    text-decoration: none;
}
a:hover,
a:focus {
  color: #23527c;
  text-decoration: underline;
}
a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}


/******** DROPDOWN *********/
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(12, 12, 12, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333333;
  white-space: nowrap;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #337ab7;
  outline: 0;
}
.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  color: #777777;
}
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}

/******** BUTTONS  *********/
.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: .65;
    box-shadow: none;
}
.btn-default.disabled, .btn-default:disabled,
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-default:focus,
.btn-default.focus {
  color: #333;
  background-color: #e6e6e6;
  border-color: #8c8c8c;
}
.btn-default:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.btn-default:active,
.btn-default.active,
.btn-default:not(:disabled):not(.disabled):active,
.btn-default:not(:disabled):not(.disabled).active,
.show > .btn-default.dropdown-toggle,
.open > .dropdown-toggle.btn-default {
  color: #333;
  background-color: #e6e6e6;
  background-image: none;
  border-color: #adadad;
}
.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus {
  background-color: #fff;
  border-color: #ccc;
}
.btn-default .badge {
  color: #fff;
  background-color: #333;
}
.btn-primary.disabled, .btn-primary:disabled,
.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}
.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #286090;
  border-color: #122b40;
}
.btn-primary:hover {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}
.btn-primary:active,
.btn-primary.active,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle,
.open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #286090;
  background-image: none;
  border-color: #204d74;
}
.btn-primary:active:hover,
.btn-primary.active:hover,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.open > .dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.open > .dropdown-toggle.btn-primary.focus {
  color: #fff;
  background-color: #204d74;
  border-color: #122b40;
}
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus {
  background-color: #337ab7;
  border-color: #2e6da4;
}
.btn-primary .badge {
  color: #337ab7;
  background-color: #fff;
}
.btn-success.disabled, .btn-success:disabled,
.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #449d44;
  border-color: #255625;
}
.btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}
.btn-success:active,
.btn-success.active,
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle,
.open > .dropdown-toggle.btn-success {
  color: #fff;
  background-color: #449d44;
  background-image: none;
  border-color: #398439;
}
.btn-success:active:hover,
.btn-success.active:hover,
.open > .dropdown-toggle.btn-success:hover,
.btn-success:active:focus,
.btn-success.active:focus,
.open > .dropdown-toggle.btn-success:focus,
.btn-success:active.focus,
.btn-success.active.focus,
.open > .dropdown-toggle.btn-success.focus {
  color: #fff;
  background-color: #398439;
  border-color: #255625;
}
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled.focus,
.btn-success[disabled].focus,
fieldset[disabled] .btn-success.focus {
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.btn-success .badge {
  color: #5cb85c;
  background-color: #fff;
}
.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}
.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #31b0d5;
  border-color: #1b6d85;
}
.btn-info:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}
.btn-info:active,
.btn-info.active,
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle,
.open > .dropdown-toggle.btn-info {
  color: #fff;
  background-color: #31b0d5;
  background-image: none;
  border-color: #269abc;
}
.btn-info:active:hover,
.btn-info.active:hover,
.open > .dropdown-toggle.btn-info:hover,
.btn-info:active:focus,
.btn-info.active:focus,
.open > .dropdown-toggle.btn-info:focus,
.btn-info:active.focus,
.btn-info.active.focus,
.open > .dropdown-toggle.btn-info.focus {
  color: #fff;
  background-color: #269abc;
  border-color: #1b6d85;
}
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled.focus,
.btn-info[disabled].focus,
fieldset[disabled] .btn-info.focus {
  background-color: #5bc0de;
  border-color: #46b8da;
}
.btn-info .badge {
  color: #5bc0de;
  background-color: #fff;
}
.btn-warning.disabled, .btn-warning:disabled,
.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}
.btn-warning:focus,
.btn-warning.focus {
  color: #fff;
  background-color: #ec971f;
  border-color: #985f0d;
}
.btn-warning:hover {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}
.btn-warning:active,
.btn-warning.active,
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle,
.open > .dropdown-toggle.btn-warning {
  color: #fff;
  background-color: #ec971f;
  background-image: none;
  border-color: #d58512;
}
.btn-warning:active:hover,
.btn-warning.active:hover,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning:active:focus,
.btn-warning.active:focus,
.open > .dropdown-toggle.btn-warning:focus,
.btn-warning:active.focus,
.btn-warning.active.focus,
.open > .dropdown-toggle.btn-warning.focus {
  color: #fff;
  background-color: #d58512;
  border-color: #985f0d;
}
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled.focus,
.btn-warning[disabled].focus,
fieldset[disabled] .btn-warning.focus {
  background-color: #f0ad4e;
  border-color: #eea236;
}
.btn-warning .badge {
  color: #f0ad4e;
  background-color: #fff;
}
.btn-danger.disabled, .btn-danger:disabled,
.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}
.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #c9302c;
  border-color: #761c19;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}
.btn-danger:active,
.btn-danger.active,
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle,
.open > .dropdown-toggle.btn-danger {
  color: #fff;
  background-color: #c9302c;
  background-image: none;
  border-color: #ac2925;
}
.btn-danger:active:hover,
.btn-danger.active:hover,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger:active:focus,
.btn-danger.active:focus,
.open > .dropdown-toggle.btn-danger:focus,
.btn-danger:active.focus,
.btn-danger.active.focus,
.open > .dropdown-toggle.btn-danger.focus {
  color: #fff;
  background-color: #ac2925;
  border-color: #761c19;
}
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled.focus,
.btn-danger[disabled].focus,
fieldset[disabled] .btn-danger.focus {
  background-color: #d9534f;
  border-color: #d43f3a;
}
.btn-danger .badge {
  color: #d9534f;
  background-color: #fff;
}
.btn-link {
  font-weight: 400;
  color: #337ab7;
  border-radius: 0;
}
.btn-link,
.btn-link:active,
.btn-link.active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
  background-color: transparent;
  box-shadow: none;
}
.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  border-color: transparent;
}
.btn-link:hover,
.btn-link:focus {
  color: #23527c;
  text-decoration: underline;
  background-color: transparent;
}
.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:focus {
  color: #777777;
  text-decoration: none;
}
.btn-lg,
.btn-group-lg > .btn {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}
.btn-sm,
.btn-group-sm > .btn {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.btn-xs,
.btn-group-xs > .btn {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
label {
  max-width: 100%;
  font-weight: 700;
}
.badge {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}
.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}
.badge-default {
  background-color: #777777;
}
.badge-default[href]:hover,
.badge-default[href]:focus {
  background-color: #5e5e5e;
}
.badge-primary {
  background-color: #337ab7;
}
.badge-primary[href]:hover,
.badge-primary[href]:focus {
  background-color: #286090;
}
.badge-success {
  background-color: #5cb85c;
}
.badge-success[href]:hover,
.badge-success[href]:focus {
  background-color: #449d44;
}
.badge-info {
  background-color: #5bc0de;
}
.badge-info[href]:hover,
.badge-info[href]:focus {
  background-color: #31b0d5;
}
.badge-warning {
  background-color: #f0ad4e;
}
.badge-warning[href]:hover,
.badge-warning[href]:focus {
  background-color: #ec971f;
}
.badge-danger {
  background-color: #d9534f;
}
.badge-danger[href]:hover,
.badge-danger[href]:focus {
  background-color: #c9302c;
}

/********* ALERT *********/
.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.alert h4 {
  margin-top: 0;
  color: inherit;
}
.alert .alert-link {
  font-weight: bold;
}
.alert > p,
.alert > ul {
  margin-bottom: 0;
}
.alert > p + p {
  margin-top: 5px;
}
.alert-dismissable,
.alert-dismissible {
  padding-right: 35px;
}
.alert-dismissable .close,
.alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}
.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
.alert-success hr {
  border-top-color: #c9e2b3;
}
.alert-success .alert-link {
  color: #2b542c;
}
.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}
.alert-info hr {
  border-top-color: #a6e1ec;
}
.alert-info .alert-link {
  color: #245269;
}
.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}
.alert-warning hr {
  border-top-color: #f7e1b5;
}
.alert-warning .alert-link {
  color: #66512c;
}
.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
.alert-danger hr {
  border-top-color: #e4b9c0;
}
.alert-danger .alert-link {
  color: #843534;
}

/******** MODAL ********/
@media (min-width: 768px) {
  .modal-dialog {
      width: 600px;
      margin: 30px auto;
      max-width: initial!important;
  }
}

.DriversSoonModal .modal-dialog,
.canteen-orders-dialog.modal-dialog{
  width: -moz-fit-content;
  width: fit-content;
}

/********* FORM *********/
@media (min-width: 768px) {
  .form-horizontal .form-label {
      padding-top: 7px;
      margin-bottom: 0;
      text-align: right;
  }
}
.form-control, output {
    display: block;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
}
.form-control {
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}


/********** POPOVER ***********/
.popover-header {
    padding: 8px 14px;
    margin: 0;
    font-size: 14px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-radius: 5px 5px 0 0;
}

/********** TABLE ***********/
.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f9f9f9;
}
.table-hover tbody tr:hover {
    background-color: #f5f5f5;
}
.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
  background-color: #f5f5f5;
}
.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8;
}
.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
  background-color: #dff0d8;
}
.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6;
}
.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
  background-color: #d9edf7;
}
.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3;
}
.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
  background-color: #fcf8e3;
}
.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc;
}
.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
  background-color: #f2dede;
}
.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc;
}


/**************** TOOLTIP ***********/
.tooltip-inner {
  background-color: #fff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

@media (min-width: 2500px) {
  .col-huge-2 {
    flex: 0 0 16.666666%;
    max-width: 16.666666%;
  }
  .col-huge-2virgule4 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-huge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-huge-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-huge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-huge-9virgule6 {
    flex: 0 0 80%;
    max-width: 80%;
  }
}

.inline-display-1400{
  display: none;
}
@media (min-width: 1400px) {
  .inline-display-1400{
    display: inline;
  }
}

@media (max-width: 575px) {
  .col-0 {
    display: none;
  }
}

.deliveries-docks-col-12.deliveries-docks-col-12.deliveries-docks-col-12 .Dock{
    flex: 0 0 100%;
    max-width: 100%;
}

