
.NextTaskForm {
    font-size: 12px;
}

.NextTaskForm.staff-active {
    font-weight: bold;
}

.NextTaskForm .is-interim {
  color:red;
}


.NextTaskForm.staff-trying-period > .name a {
  color: black !important;
}

.NextTaskForm.staff-stopped > .name a {
  color: darkred !important;
}



.NextTaskForm select {
    min-width: 150px;
    max-width: 150px;
    padding: 1px 2px 1px 2px;
    height: 20px;
    font-size: 12px;
}

.NextTaskForm.staff-active form {
    font-weight: normal;
}


.table-hover tbody tr:hover.NextTaskForm td, .table-hover tbody tr:hover th {
    background-color: lightgreen;
}

.Assign th.sortable {
    text-decoration: underline dotted;
    cursor: pointer;
}

.Assign th.sort-current {
    text-decoration: underline;
    cursor: pointer;
}

.perm-button {
    padding: 1px;
    margin: 0 1px;
    position: relative;
    z-index: 1;
}
.perm-button.loading {
  opacity: 0.6;
}
.perm-button.loading:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.3;
  left: 0;
  top: 0;
  border-radius: 3px;
  z-index: 99;
}
.grant_overly_trigger {
  cursor: pointer;
}

#grant-popover-trigger-click {
  max-width: 650px;
}
#grant-popover-trigger-click .popover-body {
  width: 650px;
  height: auto;
}
#grant-popover-trigger-click .perm-button {
  margin-bottom: 8px;
  display: block;
  float: left;
}
#grant-popover-trigger-click .perm-button.new_line {
  clear: left;
}
.nb-active-sub-grant {
  padding-left: 4px;
  font-size: 9px;
  line-height: 18px;
  vertical-align: top;
}

.tags-collection .badge.is-test {
    background-color: #F47C3C;
}
.prep_cph_by_time,
.prep_iph_by_time,
.prep_tad_by_time,
.prep_tap_by_time {
  font-size: 8px;
}
.prep_cph_by_time > span:before,
.prep_iph_by_time > span:before,
.prep_tad_by_time > span:before,
.prep_tap_by_time > span:before {
  content: " / ";
}
.prep_cph_by_time span:first-child:before,
.prep_iph_by_time span:first-child:before,
.prep_tad_by_time span:first-child:before,
.prep_tap_by_time span:first-child:before {
  content: initial;
}
.prep_cph_by_time em,
.prep_iph_by_time em,
.prep_tad_by_time em,
.prep_tap_by_time em {
  font-style: normal;
  color: grey;
}
/*
.AssignNavBar.nav {
    margin-top: 5px;
}
.AssignNavBar li a {
    padding: 2px 5px;
}

.AssignNavBar.nav-pills > li.active a {
    background-color: #BBBBBB;
    color: #333333;
}
.AssignNavBar.nav-pills > li a {
    color: #333333;
}

*/


span.badge.success {
  background-color: green;
}
span.badge.error {
  background-color: red
}