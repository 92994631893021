

.TaskName button {
    margin-left: 5px;
}

.TaskName .opz-lock, .TaskName .opz-success, .TaskName .opz-default {
    cursor: pointer;
}


.opz-popover {
    width: 180px;
}

.opz-popover .popover-body {
    width: inherit;
    height: inherit;
    padding: 5px;

}
.opz-popover p {
    margin-bottom: 0;
}

.opz-popover button {
    width: 100%;
    margin-top:10px;
}

.opz-popover button.btn-default {
    color: #FFF;
    font-size: 11px;
    background-color: #777;
}
.opz-popover button.btn-default:hover {
    background-color: #555;
}
