.DepositDriverReturn .form-group {
  margin-bottom:5px;
}
.deposit-flex {
  display: flex!important;
  height: 75px;
  float: left;
}

.deposit-center {
  align-self: center;
}
.deposit-qty input {
  display: inline-block;
  width: auto;
}
.deposit-qty span {
  display: inline-block;
  margin-left: 5px;
}
.cancel_received_disabled button {
  cursor: not-allowed!important;
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
  box-shadow: none;
  opacity: .65;
}

.DepositOrderRow {
  display: flex;
  align-items: center;
  padding: 1rem 1rem;
  margin-bottom: 0;
  border-bottom: 1px solid lightgray;
}

.DepositOrderRow p, .DepositOrderRow a {
  margin-bottom: 0;
  margin-right: 2rem;
}
