
.Row .draggable {
    /*noinspection CssUnknownTarget*/
    background-image: url("/draggable3_25.png");
    background-repeat: no-repeat;
    height: 25px;
    width: 25px;
}

.Row .draggable.locked {
    filter: alpha(Opacity=25);
    opacity: 0.25;
}

.drag-select {
    width: 40px;
    text-align: center;
}

.Row td {
    padding-left: 5px;
}

.Row.updating {
    color:dimgrey;
}

/*.Row.error {*/
    /*animation: ERROR-BLINK 2s infinite;*/

/*}*/

/*@-webkit-keyframes ERROR-BLINK {*/
    /*0%, 49% {*/
    /*}*/
    /*50%, 100% {*/
        /*background-color: #e50000;*/
    /*}*/
/*}*/


tr.is-dragging-row {
    opacity: 0.5;
    background-color: white !important;;
}
tr.is-over-row,
tr.is-over-row td {
    background-color: LightGreen !important;

}

tr.Row.DeliveryRow.received  {
    background-color: lightgreen!important;
}

tr.Row.DeliveryRow.late_delivery_expected  {
    background-color: #f18043 !important;
}

tr.Row.DeliveryRow.late_delivered  {
    background-color: #f1723c !important;
}

/*noinspection CssUnusedSymbol*/
tr.Row.DeliveryRow.soon_delivery_expected  {
    background-color: #b982f6 !important;
}

/*noinspection CssUnusedSymbol*/
tr.Row.DeliveryRow.soon_delivered  {
    background-color: #8b6fd5 !important;
}

tr.Row.DeliveryRow.undeliveredclient  {
    background-color: lightcoral!important;
}

tr.Row.DeliveryRow.cross_dock_warning.cross_dock_warning  {
    background-color: red!important;
}
tr.Row.DeliveryRow.express_prio_warning.express_prio_warning,
tr.Row.DeliveryRow.express_prio_warning.express_prio_warning td,
tr.Row.DeliveryRow.dqp_courier_warning.dqp_courier_warning.dqp_courier_warning td:not(.slots)
{
    background-color: red!important;
}

@keyframes flashy {
from { background-color: red; }
to { background-color: white; }
}

/* https://www.developpez.net/forums/d1730383/webmasters-developpement-web/mise-page-css/faire-clignoter-div-css/ */
tr.Row.DeliveryRow.dqp_courier_warning.dqp_courier_warning.dqp_courier_warning.dqp_courier_warning td.slots,
tr.Row.DeliveryRow.dqp_courier_warning_soft.dqp_courier_warning_soft.dqp_courier_warning_soft.dqp_courier_warning_soft td.slots
{
    animation: flashy 2s infinite alternate;
}

tr.Row.DeliveryRow.late-list .Slot .eta-label {
    display: none;
}

tr.Row.PackingRow.vip_warning.vip_warning  {
  background: rgba(255, 0, 0, 0.25) !important;
}

tr.Row.PackingRow.venere_warning.venere_warning  {
  background: rgba(250, 241, 9, 0.2) !important;
}

.Row.canceled:not(.OrderRow)  {
    background-color: lightcoral!important;
}

.menu-actions .popover-body {
    padding: 0 2px 2px 2px;
    font-size: 11px;
    height: auto;
}

.menu-actions button {
    width: 100%;
    margin-bottom: 10px;
}


.Row .tags-collection .badge {
    margin-left: 2px;
    padding-left: 1px;
    padding-right: 1px;
    font-weight: normal;
    /*background-color: purple;*/
}
/*.Row .tags-collection .badge.weight {*/
.badge.weight {
    background-color: #8989e2;
}

/*.Row .tags-collection .badge.weight.heavy {*/
.badge.weight.heavy {
    background-color: #7D00DC;
}

/*.Row .tags-collection .badge.weight.heavy {*/
.badge.weight.b2c-undeliverable {
    color: orange;
    font-weight: bold;
}

/*.Row .tags-collection .badge.is-tr {*/
.badge.is-tr {
    background-color:   #604dff;
}

/*.Row .tags-collection .badge.is-ot {*/
.badge.is-ot {
    background-color:   #3a4570;
}

/*.Row .tags-collection .badge.is-pro {*/
.badge.is-pro {
    background-color: #ff884d;
}

.badge.goblast {
  background-color: cadetblue;
  color: white;
}

/*.Row .tags-collection .badge.prio {*/
.badge.prio {
    background-color: yellow;
    color: black;
}

/*.Row .tags-collection .badge.courier {*/
.badge.courier {
    background-color: yellow;
    color: black;
}

/*.Row .tags-collection .badge.is-super-venere {*/
.badge.is-super-venere {
    background-color: yellow;
    color: black;
}

/*.Row .tags-collection .badge.missing-bags {*/
.badge.missing-bags {
    background-color: #cd4ce1;
    color: white;
}

/*.Row .tags-collection .badge.redelivering {*/
.badge.redelivering {
    background-color: springgreen;
    color: black;
}

.badge.lgep {
    background-color: black;
    color: white;
}

.badge.frichti {
    background-color: #faf190;
    color: black;
}

.badge.ubereats {
  background-color: #06C167;
  color: black;
}

.badge.uberdirect {
    background-color: black;
    color: white;
}

.badge.uberdirect.error {
    background: repeating-linear-gradient(
        to bottom,
        black,
        black 2px,
        red,
        red 4px
        );
    color: white;
}

/*.Row .tags-collection .badge.is-staff {*/
.badge.is-staff {
    background-color:   #604dff;
    color: #ffffff;
}

/*.Row .tags-collection .badge.is-exchange {*/
.badge.is-exchange {
    background-color: #4dadff;
    color: #ffffff;
}

/*.Row .tags-collection .badge.is-oneprep {*/
.badge.is-oneprep {
    background-color: #91ff00;
    color: #000;
}

/*.Row .tags-collection .badge.is-fraud {*/
.badge.is-fraud {
    background-color: #000000;
    color: #ffffff;
}

/*.Row .tags-collection .badge.loading-inprogress {*/
.badge.loading-inprogress {
    background-color: indianred;
    color: #ffffff;
}

/*.Row .tags-collection .badge.loading-done {*/
.badge.loading-done {
    background-color: limegreen ;
    color: #ffffff;
}

.Row .frozen-icon {
  color: #01c1ff;
  margin-left: 2px;
}
.Row .frozen-icon.doing {
  color: orangered;
  cursor: pointer;
}
.Row .frozen-icon.done {
  color: #02c502;
  cursor: pointer;
}
