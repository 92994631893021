.drivers {
    margin: 0 0 0 0;
}

.drivers .panel {
    margin-bottom: 10px;
}

.drivers-list {
    display: flex;
    flex-flow: row wrap;

    padding: 5px 5px 0 5px !important;
    background-color: #cDcDcD;
}

.drivers-list .DriverState {
    /*float: left;*/
    padding: 2px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 11px;
}
.drivers-list .DriverState.launchpad-default {
    background-color: #FFFFFF;
}

.drivers-list .DriverState.launchpad-basket {
    background-color: #a5aed1;
}



.drivers-list .DriverBurger {
    /*float: left;*/
    padding: 0 5px 0 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 11px;
    font-weight: bold;
    height: 24px;
}



.driver-actions {
    text-align: center;
}

.driver-actions .popover-body {
    width: 180px;
}
.driver-actions .popover-body, .driver-actions button {
    padding: 0 2px 2px 2px;
    font-size: 11px;
}



.driver-actions h5 {
    margin-top: 2px;
    margin-bottom: 2px;
    padding: 2px 0 2px 0;
    font-weight: bold;
    background-color: lightgoldenrodyellow;
    font-size: 14px;
    width: 170px;

}

.driver-actions input {
    margin: 0 5px 0 5px;
    width: 60px;
}

/*.driver-actions .normal-case {*/
/*    width: 130px;*/
/*}*/

/*.driver-actions-present, .driver-actions-absent {*/
/*    width: 130px;*/
/*}*/
.driver-actions-present button, .driver-actions-absent button{
    margin-bottom: 5px;
    margin-top: 5px;
    width: 170px;
}

/*.driver-actions-present {*/
/*    width: 130px;*/
/*}*/

.DriverState.noplan button.btn-primary {
    background-color: red;
    border-color: red;
}
