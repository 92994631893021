/*body {*/
  /*margin: 0;*/
  /*padding: 0;*/
  /*font-family: sans-serif;*/
/*}*/

.OrderInfo {
    position:fixed;
    top: 0;
    right: 0;
    z-index:2;
    width:50%;
    height:100%;
    background-color:white;
}

.pz-btn input{
    margin-top: 2px;
}
