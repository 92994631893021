.OrderSummary .badge{
    margin-right: 5px;
}
.OrderPrepZoneDisplay > span:not(.OrderPrepZoneAction):before {
  content: ', ';
}
.OrderPrepZoneDisplay > span:first-child:before {
  content: '';
}
.OrderPrepZoneDisplay > span:hover {
  color: black;
  cursor: pointer;
}
.OrderPrepZoneDisplay[disabled] {
  opacity: 0.5;
  pointer-events: none;
}
