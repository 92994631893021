.DriverQueue {
  margin-left: 2px;
  vertical-align: middle !important;
}
.DriverQueue.badge-car {
  background-color: #8c8cff;
}
.DriverQueue.badge-s50 {
  background-color: #ff4e4e;
}
.DriverQueue.badge-s125 {
  background-color: #00b400;
}
.DriverQueue.badge-selec {
  background-color: yellow;
  color: black;
}
.DriverQueue.badge-bike {
  background-color: purple;
}
.DriverQueue.badge-truck {
  background-color: lightseagreen;
}
