
.DriverLogs ul {
    padding-left: 0;
}

.DriverLogs li {
    margin-left: 20px;

}
.DriverLogs li:hover {
    background-color: lightgray;
}

.DriverLogs .presence-title {
    padding: 2px 0 2px 2px;
    border: 1px solid lightgray;
    font-size: 13px;
    height: 25px;
}
.DriverLogs .presence-title.running {
    background-color: lightgreen;
}

.DriverLogs .presence-title.updating {
    color: grey;
    opacity: 0.6;
    cursor: progress;
}


.DriverLogs .presence-title:hover {
    background-color: lightgray;
}

.DriverLogs .presence-title .edit input {
    height: 18px;
    padding: 1px 0 1px 0;
    width: 50px;
    margin-right: 5px;
}

.DriverLogs .presence-title .edit button {
    margin-right: 5px;
}
.ExtraCostDelete button {
  /*noinspection CssNonIntegerLengthInPixels*/
  line-height: 1.3px;
  font-size: 9px;
}
