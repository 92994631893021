.order-row.done {
  background-color: #05a356;
  color: white;
}

.order-row.prepared {
  background-color: #2ff896;
}

.order-row.preparing {
  background-color: #c3fde0;
}

.uber-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;;
  background-color: lightyellow;
  margin: 1rem 0 0.5rem 0; 
}

.uber-section-header > h4 {
  margin: 0;
}

.uber-status-scheduled {
  background-color: honeydew;
  padding: 0 0.2rem;
}

.uber-status-en_route_to_pickup {
  background-color: lightgreen;
  padding: 0 0.2rem;
}

.uber-status-arrived_at_pickup {
  background-color: mediumseagreen;
  padding: 0 0.2rem;
  color: white;
}

.uber-status-en_route_to_dropoff {
  background-color: green;
  color: white;
  padding: 0 0.2rem;
}

.uber-status-completed {
  background-color: darkgreen;
  color: white;
  padding: 0 0.2rem;
}

.uber-status-failed {
  background-color: #d9534f;
  color: white;
}
