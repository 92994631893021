@media(min-width: 1200px) {
    .OptimizeDockModal .modal-dialog.modal-xl {
        width: 80vw;
    }
}
.route-compare-container table, h2 {
    margin: 30px 0;
}
.route-compare-container tr {
    border-bottom: solid 1px #eee;
}

.route-compare-container tr:nth-child(even) {
    background: #efefef;
}

.route-compare-container tr td {
    padding: 5px 10px;
}

.route-compare-container tr td:last-child {
    --padding-left: 40px;
}