.Dispatch .DeliveryRow .TransitArea {
  min-width: 110px;
}
.Dispatch .col-huge-2 .DeliveryRow .TransitArea,
.Dispatch .col-huge-2virgule4 .DeliveryRow .TransitArea {
  min-width: 30px;
}
.Dispatch .DeliveryRow.is_moving {
  opacity: 0.7;
}
.Dispatch .DeliveryRow .draggable .CancelDeliveryDrop {
  margin: 25px 0 0 0;
}
.Dispatch .DeliveryRow .more-icon {
  display: block;
}
.Dispatch .DeliveryRow .moving-icon {
  color: #ef9647;
}
.Dispatch .DeliveryRow.is-dragging-row > td {
  background-color: rgb(253 229 60 / 60%)!important;
}
.Dispatch .DeliveryRow .prep_progress {
  white-space: nowrap;
  margin-left: 2px;
}
.Dispatch .DeliveryRow .prep_progress i {
  margin-left: 0;
}

.Dispatch .DeliveryRow.to-check,
.Dispatch .DeliveryRow.waiting-products {
  background-color: #e7ccff;
}

.Dispatch .DeliveryRow .TransitArea .not_loaded_on_trolley {
  color: red;
}

.Dispatch .DeliveriesToDispatch table {
  table-layout: fixed;
}

/* depend de table-layout: fixed; */
.Dispatch .Row  .slots {
  white-space: nowrap;
  width: 80px;
}

.Dispatch .Row  .btn-stuart {
  white-space: nowrap;
  width: 50px;
  padding-left: 5px !important;
}

.Dispatch .Row  .zonage {
  min-width: 25px;
  max-width: 90px;
}

.Dispatch .Row .bags {
  width: 25px;
  white-space: nowrap;
}

.Dispatch .Row.type-dock .slots {
  width: 80px;
  white-space: nowrap;
}

.Dispatch .Row.type-dock  .btn-stuart {
  white-space: nowrap;
  width: 50px;
  padding-left: 5px !important;
}

.Dispatch .DeliveryRow.address-invalid td.address {
  background-color: #ff0101;
}

@media (max-width:1899px) {
  .Dispatch .Row td.status, .Dispatch .Row td.client-name {
    display: none;
  }
}

@media (min-width:1899px) {
  .Dispatch .Row td.status, .Dispatch .Row td.client-name {
    display: table-cell;
  }
  .Dispatch .Row td.status {
    width: 90px;
  }
}
