

.AdminCompactHeader {
    padding: 5px 0 5px 0;
    position: relative;
    z-index: 10;
}

.AdminCompactHeader .sync {
    position:fixed;
    right: 20px;
}

.AdminCompactHeader .sync .title {
    font-size:1.5rem;
}

.AdminCompactHeader .sync a {
    text-align: left;
}

.AdminCompactHeader .btn{
    margin-right: 5px;
}

.AdminCompactHeader select {
    width: 100px;
    float: right;
}

.AdminCompactHeader .pseudo-area {
    margin-left: 5px;
    margin-right: 5px;
    float: right;
}
