.Dispatch .Dock {
  border: 1px solid lightgrey;
  padding: 0;
}

.Dispatch .Dock .draggable.locked {
  filter: alpha(Opacity=25);
  opacity: 0.25;
}

.Dispatch .Docks .Dock.driver_hard .DockTitle .DockReturnSetting {
  width: 70px;
}
.Dispatch .Docks .Dock.driver_hard .DockTitle .DockReturnSetting select{
  width: 70px;
}

.Dispatch .Dock .dock_top .DockTitle .dock_name {
  flex-grow: 8;
}
.Dispatch .Dock .dock_top .DockTitle form {
  order: 2;
  justify-content: end;
  flex-grow: 0;
}

.DockList.deliveries-todo .Dock.locked:not(.stuart):not(.uber) thead tr:first-child  {
  background-color: lightgreen;
}

.Dock.stuart thead tr:first-child {
  background-color: #0192ff;
  color: white;
}

.Dock.canceled-external-job thead tr:first-child {
  background-color: indianred;
}

.Dock.uber:not(.canceled-external-job) thead tr:first-child {
  background-color: black;
  color: white;
}

.Dock {
  padding:0 5px 0 0;
}



.Dock .dock_top form.updating {
  color:Lightgrey;
}

.Dock .dock_top .subheader {
  font-size: 11px;
  font-weight: 300;
  background-color: white;
  height: 13px;
}

.Dock select.form-control {
  padding: 1px 2px 1px 2px;
  height: 20px;
  font-size: 11px;
  width: 100px;
}

.Dock .form-group  {
  height: 20px;
  margin-bottom: 0;
}

.Dock .last-run {
  margin-left: 5px;
}

.Dock .btn {
  margin-left: 5px;
  padding: 0 5px 0 5px;
  font-size: 11px;
}

.Dock i {
  margin-left: 2px;
}

.Dock .DefaultDropZone td {
  text-align: center;
}

.Dock .draggable {
}
.Dock .draggable.locked {
  filter: alpha(Opacity=25);
  opacity: 0.25;
}

.Dispatch .Dock .DefaultDropZone {
  background-color: transparent;
  height: 100%;
}


.Dock.is-dragging-dock {
  opacity: 0.5;
  background-color: white !important;
}

.Dock.is-over-dock  {
  /*background-color: LightGreen !important;*/
  border-color: LightGreen;
  border-width: 2px;
}

.Dock .first-cmd {
  display: none;
}

.Dock .express {
  display: initial;
}

.Dock .OrderPseudoZoneName {
  display: none;
}

.Dock .link_admin {
  color: black;
  font-size: 1.2em;
  margin-left: 1.5em;
}
