.empty-info {
    color: #cccccc;
}

.invalidAddress {
    background: lightpink;
}

.tooltip-order-stat-driver .tooltip-inner {
  text-align: left;
  max-width: 500px;
}
.tooltip-order-stat-driver ul {
  list-style: none;
  padding-left: 2px;
}
.tooltip-order-stat-driver ul li ul {
  padding-left: 10px;
}

.ModalStatDriver .modal-dialog {
  width: 70vw;
}

.PopoverStatDriverDetail {
  width: 40vw;
  max-width: initial !important;
}
.PopoverStatDriverDetail .popover-body {
  height: auto;
  min-height: 120px;
  max-height: 70vh;
  width: 100%;
}
