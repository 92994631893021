body .Dispatch {
  font-size: 11px;
}
.Dispatch .Deliveries {
  max-height: calc(100vh - 35px);
  /* overflow-y: auto; */
  padding: 0 0 0 2px!important;
}

.Dispatch .Docks {
  max-height: calc(100vh - 35px);
  overflow-y: auto;
  padding: 0 2px 0 5px!important;
}

.Dispatch .Docks .DockList h4.row {
  display: block;
}
