.PackingList {

}

.PackingList .title-right {
    font-size: 14px;
    color: #333333;
}

.PackingList.waiting-products-list .SelectStatus select{
    width: 50px;
}

.ReadyToGoButton {
    margin-left: 5px;
}


.PackingList .day-label {
    font-weight: bold;

}


.blocked-list tr.Row {
    background: rgba(255, 0, 0, 0.44) !important;
}
.blocked-list .Row.updating {
    color:inherit;
}
