

.SelectStatus select {
    width:80px;
    padding: 1px 2px 1px 2px;
    height: 20px;
    font-size: 12px;
}

div.SelectStatus  {
    margin-bottom: 0;
}

