
.ais-SearchBox input.ais-SearchBox-input {
    padding-left: 22px;
    font-size: 18px;
    height: 22px;
}

.ais-Menu {
    /*border: 1px solid #CCCCCC;*/

}

.ais-Menu .ais-Menu-showMore {
    background-color: #FFFFFF;
    text-decoration: underline;
    color: #333;
    font-size: 11px;
    margin-top: 0;
}


.ais-InstantSearch__root tr.hit td {
    cursor: pointer !important;
}




