.DepositModalDrivers .modal-dialog,
.DepositModalDriver .modal-dialog {
  width: 60vw;
  min-width: 600px;
  max-width: initial;
}
.DepositModalDriver .form-horizontal .control-label {
  text-align: left;
}
.DepositModalDrivers .table td,
.DepositModalDriver .table td {
  vertical-align: middle;
}
.DepositDrivers,
.DepositDriver {
  min-height: 200px;
  position: relative;
}
.DepositDrivers .loading,
.DepositDriver .loading {
  position:absolute;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}
.DepositDrivers .loading .loading-back,
.DepositDriver .loading .loading-back {
  position:absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.7;
}
.DepositDrivers .loading .loading-content,
.DepositDriver .loading .loading-content {
  position: absolute;
  top: 100px;
  left: 50%;
  max-height: 100vh;
  transform: translate(-50%, -50%);
}
.DepositDriverHistory .table-condensed td, th { padding: 0 2px!important; }
.DepositDriver .badge { margin-right: 2px; }
.DepositDriver .badge.deposit-cancel { background-color: #a800ab; color: white; }
.DepositDriver .badge.deposit-fix { background-color: red; color: white; }
.DepositDriver .badge.deposit-user { background-color: orange; color: black; }
.DepositDriver .badge.deposit-driver { background-color: #c1f1a8; color: black; }
.DepositDriver .badge.deposit-fix-partial { border:1px solid #0097A7; background-color: #E0F7FA; color: black; }
.DepositDriver .badge.deposit-fix-total { background-color: #0097A7; color: white; }
.DepositDriver .badge.deposit-canceled-partial { border: 1px solid #b416e8; background-color: #f3d0ff; color: black; }
.DepositDriver .badge.deposit-canceled-total { background-color: #b416e8; color: white; }
.deposit-popover { max-width: 500px!important; }
.deposit-popover .popover-body { width: 500px; height: auto; }
#deposit-fix-form .info-quantity { padding-left: 0; }
#deposit-fix-form .info-quantity .control-label { text-align: left; }
#deposit-detail-form { max-width: 600px!important; }
#deposit-detail-form .popover-body { width: 600px; height: auto; }



