

.OrderProduct .updating {
    color:grey;
}

.OrderProduct .badge {
    margin-left: 5px;
}
.OrderProduct .badge:first-of-type {
    margin-left: 0;
}
.duplicate-order-dialog{
    width: 50%!important;
}